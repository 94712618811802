<template>
  <overlay>
    <modal-window>
      <a href="#"
         @click.prevent="$router.push('/')"
         class="modal-window__close">x</a>
      <validation-observer v-slot="{ handleSubmit }">
        <form action="#"
              @submit.prevent="handleSubmit(loginProcess)">
          <div class="row">
            <div class="col">
              <validation-provider rules="required|email" v-slot="{ errors }">
                <input type="text"
                       name="login_email"
                       v-model="loginEmail"
                       :placeholder="i18n.AUTH.EMAIL"
                       class="field">
                <label class="error">{{ i18n['VALIDATE'][errors[0]] }}</label>
              </validation-provider>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <validation-provider rules="required" v-slot="{ errors }">
                <input type="password"
                        name="login_password"
                        v-model="loginPassword"
                        :placeholder="i18n.AUTH.PASSWORD"
                        class="field" />
                <label class="error">{{ i18n['VALIDATE'][errors[0]] }}</label>
              </validation-provider>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <btn>{{i18n.AUTH.LOGIN}}</btn>
            </div>
          </div>
        </form>
      </validation-observer>
    </modal-window>
  </overlay>
</template>

<script>
import Overlay from '@/components/shared/modal-overlay'
import ModalWindow from '@/components/shared/modal-window'
import Btn from '@/components/shared/btn'
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate'
import { required, email } from 'vee-validate/dist/rules'
import {mapGetters} from "vuex";

extend('required', {
  ...required,
  message: 'REQUIRED',
})

extend('email', {
  ...email,
  message: 'EMAIL',
})

export default {
  components: {
    Overlay,
    ModalWindow,
    Btn,
    ValidationProvider,
    ValidationObserver,
  },
  computed: {
    ...mapGetters('session', [
      'i18n',
    ]),
  },
  data() {
    return {
      loginEmail: '',
      loginPassword: '',
    }
  },
  methods: {
    async loginProcess() {
      try {
        await this.$store.dispatch('auth/AUTH_REQUEST', {
          email: this.loginEmail,
          password: this.loginPassword,
        });
      } catch (e) {
        await this.$swal({
          title: 'Інформація',
          text: 'Помилка. Перевірте будь-ласка правильність логіна/пароля.',
          width: 375,
          confirmButtonText: 'OK',
          confirmButtonColor: '#C70552',
          customClass: {
            header: 'sweet-modal__header',
            title: 'sweet-modal__title',
            popup: 'sweet-modal__popup',
            content: 'sweet-modal__content',
            actions: 'sweet-modal__actions',
            confirmButton: 'sweet-modal__ok-button',
          },
        })
      }
      await this.$router.push({ path: '/my-door' })
    },
  },
}
</script>
